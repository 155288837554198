<template lang="pug">
    .main-wrapper.unidades-salvar

        Dialog(header='Adicionar subgrupo' :visible.sync='dialogSubgrupo' :modal='true')
            .p-grid.p-fluid.p-align-center(style='width: 400px')
                .p-col-12
                    label.form-label Subgrupo:
                    MultiSelect(v-model='model.cd_subgrupos_f' appendTo='body' :options='options.subgrupos' :filter='true' display='chip' dataKey='value' optionLabel='text' optionValue='value' placeholder='- Selecione -' )
                      
            .ta-center.mt-2
                Button(label='Adicionar' @click='addSubgrupo()')
        
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "procedimentos" }'> Procedimentos</router-link> /
                            <b>{{ model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } procedimento`")
                        .p-grid.p-fluid.p-justify-center
                            .p-col-6.p-grid.p-fluid
                                .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_tipo_procedimento.$error }")
                                    label.form-label Tipo:
                                    Dropdown(v-model='$v.model.cd_tipo_procedimento.$model' :options='options.tipo_procedimentos'
                                        optionLabel='ie_tipo_procedimento' optionValue='id' placeholder='Selecione')
                                    .feedback--errors(v-if='submitted && $v.model.cd_tipo_procedimento.$error')
                                        .form-message--error(v-if="!$v.model.cd_tipo_procedimento.required") <b>Tipo</b> é obrigatório.

                                .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_grupo_procedimento.$error }")
                                    label.form-label Grupo Procedimento:
                                    Dropdown(v-model='$v.model.cd_grupo_procedimento.$model' :options='options.grupo_procedimentos'
                                        optionLabel='ds_descricao' optionValue='id' placeholder='Selecione')
                                    .feedback--errors(v-if='submitted && $v.model.cd_grupo_procedimento.$error')
                                        .form-message--error(v-if="!$v.model.cd_grupo_procedimento.required") <b>Grupo</b> é obrigatório.
                                .p-col-12
                                    Panel.my-2(header='Subgrupos:' :toggleable='true')
                                        DataTable(v-if="model.cd_subgrupos.length" :value="model.cd_subgrupos" :filters="filters_table" style="max-height: 500px; overflow-y: auto;")
                                            Column(headerStyle='width: 10%;' bodyStyle='text-align: left;')
                                                template(#body='props')
                                                    ProgressSpinner.waitingStatus(v-if='waitingStatusSubgrupo === props.data.id' strokeWidth='6')
                                                    .status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatusSubgrupo(props.data)')
                                                        i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                                            Column(headerStyle='width: 50%;' bodyStyle='text-align: left;' field='ds_valor' header='Subgrupo' filterMatchMode="contains" sortable)
                                                template(#filter)
                                                    InputText(
                                                        type="text"
                                                        v-model="filters_table['ds_valor']"
                                                        class="p-column-filter"
                                                        placeholder="Subgrupos"
                                                    ) 
                                                template(#body='props')
                                                        span {{ props.data.ds_valor }}

                                            Column(headerStyle='width: 15%; text-align: center' header="Ações")
                                                template(#body='props')
                                                    .ta-center
                                                        Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
                                                            v-tooltip.top="'Remover'"
                                                            icon="jam jam-minus-circle"
                                                            type="button"
                                                            @click="removeSubgrupo(props.data.ie_valor)"
                                                        )
                                        .ta-center.mt-2
                                            Button(label='Adicionar Subgrupo' type="button"  icon="pi pi-plus" iconPos="left" style='max-width: 50%' @click='openDialogSubgrupo()')

                                .p-col-12
                                    label.form-label Código:
                                    InputMask(type='tel' mask="999999?99" v-model='$v.model.cd_procedimento.$model')
                                    .feedback--errors(v-if='submitted && $v.model.cd_procedimento.$error')
                                        .form-message--error(v-if="!$v.model.cd_procedimento.minLength") <b>Código do Procedimento</b> deve ter pelo menos 6 caracteres.
                                        .form-message--error(v-if="!$v.model.cd_procedimento.required") <b>Código do Procedimento</b> é obrigatório.

                                .p-col-12(:class="{ 'form-group--error': submitted && $v.model.nm_procedimento.$error }")
                                    label.form-label Nome do Procedimento:
                                    InputText(type='text' v-model='$v.model.nm_procedimento.$model')
                                    .feedback--errors(v-if='submitted && $v.model.nm_procedimento.$error')
                                        .form-message--error(v-if="!$v.model.nm_procedimento.minLength") <b>Nome do Procedimento</b> deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.nm_procedimento.required") <b>Nome do Procedimento</b> é obrigatório.

                                .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_procedimento.$error }")
                                    label.form-label Descrição:
                                    InputText(type='text' v-model='$v.model.ds_procedimento.$model')
                                    .feedback--errors(v-if='submitted && $v.model.ds_procedimento.$error')
                                        .form-message--error(v-if="!$v.model.ds_procedimento.minLength") <b>Descrição do Procedimento</b> deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.ds_procedimento.required") <b>Descrição do Procedimento</b> é obrigatório.

                                .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_sinonimia.$error }")
                                    label.form-label Sinônimos:
                                    InputText(type='text' v-model='$v.model.ds_sinonimia.$model')

                                .p-col-12.p-md-10(:class="{ 'form-group--error': submitted && $v.model.ds_sigla.$error }")
                                    label.form-label Sigla:
                                    InputText(type='text' v-model='$v.model.ds_sigla.$model')

                                .p-col-12.p-md-2.mb-4.ta-center
                                    label.form-label Status:
                                    InputSwitch(v-model='model.ie_status')

                                .p-col-12.mt-2.mb-2(v-if="model.id")
                                    .p-grid
                                        .p-col-12.p-md-6
                                            span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                        .p-col-12.p-md-6
                                            span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                        .p-col-12.p-md-6
                                            span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                        .p-col-12.p-md-6
                                            span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                                .p-col-12
                                    Button(label='Salvar' type="submit" )

                            .p-col-6(v-if="model.id")
                                Panel.my-2(header='Filtros' :toggleable='true')
                                    .p-grid.p-fluid
                                        .p-col-12.p-md-3
                                            label.form-label Status:
                                            .p-inputgroup
                                                Dropdown(
                                                    :options='options.ie_status'
                                                    placeholder='TODOS'
                                                    optionLabel='label'
                                                    optionValue='value'
                                                    dataKey='value'
                                                    v-model='filters.ie_status'
                                                    @change='alterarLista()'
                                                )
                                Panel.my-2(header='Estabelecimentos:' :toggleable='true')
                                    //- label.form-label Estabelecimentos:
                                    div(v-if='!showEstabelecimentos.length')
                                        p.ta-center (Sem estabelecimentos associados)
                                    DataTable(v-else :value="showEstabelecimentos" style="max-height: 500px; overflow-y: auto;")
                                        Column(headerStyle='width: 10%;' bodyStyle='text-align: left;')
                                            template(#body='props')
                                                ProgressSpinner.waitingStatus(v-if='waitingStatus === props.data.id' strokeWidth='6')
                                                .status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatus(props.data.id)')
                                                    i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                                        Column(headerStyle='width: 50%;' bodyStyle='text-align: left;' field='nm_fantasia' header='Estabelecimento')
                                        Column(headerStyle='width: 20%;' bodyStyle='text-align: center;' field='nr_valor_negociado_f' header='Valor Negociado')
                                        Column(headerStyle='width: 20%;' bodyStyle='text-align: center;' field='nr_valor_tarifa_f' header='Valor Tarifa')

</template>

<style lang="scss">
    .p-multiselect-panel {
        position: absolute;
        z-index: 10000;
    }
    .unidades-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
        .spinner-small {
            max-width: 23px;
            height: auto;
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 23px;
            height: 23px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            cursor: pointer;
            padding-right:10px;
            &:hover { background-color: #84b553; }
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
                &:hover { background-color: #b33030; }
            }
            .jam {
                color: #fff;
                margin-left:2px;
            }
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import DataTable from 'primevue/datatable'
    import MultiSelect  from 'primevue/multiselect';
    import Column from 'primevue/column'
    import InputSwitch from 'primevue/inputswitch'
    import ProgressSpinner from 'primevue/progressspinner'
    import Dialog from 'primevue/dialog'
    import moment from 'moment'
    import _ from 'lodash'

    import { Procedimentos, ProcedimentosEstabelecimento, DominioValor } from './../../middleware'
    import { required, minLength, numeric } from 'vuelidate/lib/validators'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, InputSwitch, Dialog,
                InputMask, SelectButton, Dropdown, Tooltip, DataTable, Column, MultiSelect, ProgressSpinner
        },
        directives: { tooltip: Tooltip },
        created () {
            Procedimentos.getGrupoProcedimentos().then(response => {
                if (response.status == 200) {
                    this.options.grupo_procedimentos = response.data
                }
            })

            Procedimentos.getTipoProcedimentos().then(response => {
                if (response.status == 200) {
                    this.options.tipo_procedimentos = response.data
                }
                let id = 0
                if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
                if (id != 0) {
                    Procedimentos.find(id).then(response => {
                        if (response.status == 200) {
                            let keys = Object.keys(this.model)

                            keys.forEach(key => this.model[key] = response.data[key])

                            this.model.id = id
                            this.model.dt_criado_f = response.data.dt_criado ?
                                moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm') : ""
                            this.model.dt_atualizado_f = response.data.dt_atualizado ?
                                moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm') : ""
                            this.model.procedimento_estabelecimento.forEach(function(item){
                                item.nr_valor_negociado_f = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                                        .format(item.nr_valor_negociado)
                                item.nr_valor_tarifa_f = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                                            .format(item.nr_valor_tarifa)
                            })
                            if(this.model.cd_subgrupos.length > 0){
                                this.model.cd_subgrupos_f = this.model.cd_subgrupos.map(item => item.ie_valor)
                            }
                            this.showEstabelecimentos = this.model.procedimento_estabelecimento.filter(item=> item.ie_status)
                        }
                        this.waiting = false
                    })
                } else this.waiting = false
            })
            this.getDominios()
        },
        data () {
            return {
                model: {
                    cd_procedimento:'',
                    nm_procedimento:'',
                    ds_procedimento: '',
                    ds_sinonimia: '',
                    ds_sigla: '',
                    ie_status: true,
                    cd_subgrupos: [],
                    cd_subgrupos_f: [],
                    dt_vigencia_inicial: moment()._d,
                    cd_tipo_procedimento:null,
                    cd_grupo_procedimento:null,
                    procedimento_estabelecimento: [],
                    nm_usuario_cri: '',
                    nm_usuario_edi: '',
                },
                options: {
                    tipo_procedimentos: [],
                    grupo_procedimentos: [],
                    subgrupos: [],
                    ie_status: [
						{ value: null, label: 'TODAS' },
						{ value: true, label: 'Ativos' },
						{ value: false, label: 'Inativos' }
					],
                },
                waiting: true,
                waitingStatus: false,
                waitingStatusSubgrupo: false,
                waitingForm: false,
                dialogSubgrupo: false,
                submitted: false,
                filters_table: {},
                filters: {
                    ie_status: true,
                    ds_valor: '',
                },
                list: [],
                showEstabelecimentos: [],
            }
        },
        validations () {
            let v = {
                model: {
                    cd_procedimento: { required, numeric, minLength: minLength(6) },
                    nm_procedimento: { required, minLength: minLength(2) },
                    ds_procedimento: { required, minLength: minLength(2) },
                    cd_tipo_procedimento: { required },
                    cd_grupo_procedimento: { },
                    ie_status: { required },
                    ds_sinonimia: { },
                    ds_sigla: { },
                }
            }

            return v
        },
        methods: {
            applyFilters () {
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
				params.order = `${ this.order.sortOrder === -1 ? '-' : '' }${ this.order.field }`
				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
				this.getList(params)
			},
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)

                this.waitingForm = true
                Procedimentos.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'procedimentos' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                        this.$router.go()
                    } else {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        else if (response.data.detail) {
                            if (typeof response.data.detail == 'string')
                                this.$toast.error(response.data.detail, { duration: 3000 })
                            else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                        }
                    }
                    this.waitingForm = false
                })

            },
            getDominios() {
				DominioValor.find('SUBGRUPOS', {}).then(response => {
					if (response.status === 200) {
                        console.log(response)
                        this.options.subgrupos = response.data.map(subgrupo => ({
                            value: subgrupo.ie_valor,
                            text: `${subgrupo.ds_valor} ${subgrupo.ie_status ? ' ' : ' (INATIVO)'}` ,
                            ie_status: subgrupo.ie_status,
                            ds_subgrupo: subgrupo.ds_valor,
                            id: subgrupo.ie_valor
                        }))

					}
				});
			},
            alterarLista(){
                if (this.filters.ie_status)
                    this.showEstabelecimentos = this.model.procedimento_estabelecimento.filter(item => item.ie_status)
                else if (this.filters.ie_status == false)
                    this.showEstabelecimentos = this.model.procedimento_estabelecimento.filter(item => !item.ie_status)
                else
                    this.showEstabelecimentos = this.model.procedimento_estabelecimento
            },
            openDialogSubgrupo() {
                this.dialogSubgrupo = true
            },
            addSubgrupo(){
                this.options.subgrupos.forEach(item => {
                    let aux = {
                            ie_valor: item.value,
                            ie_status: item.ie_status,
                            ds_valor: item.text
                        }
                    if(this.model.cd_subgrupos_f.includes(item.value)){
                        this.model.cd_subgrupos.push(aux)
                    }

                })
                this.model.cd_subgrupos = _.uniqBy(this.model.cd_subgrupos, 'ie_valor')
                this.dialogSubgrupo = false
            },
            removeSubgrupo(index) {
                const auxIndex = this.model.cd_subgrupos.findIndex(
                    item => item.ie_valor == index
                );
                this.model.cd_subgrupos.splice(auxIndex, 1);
            },

            alterarStatusSubgrupo(data) {
                this.waitingStatusSubgrupo = true
                data.ie_status = ! data.ie_status
                this.waitingStatusSubgrupo = false
            },
            alterarStatus (id) {
                let procedimento = this.model.procedimento_estabelecimento.find(a => a.id === id)
                this.waitingStatus = id
                ProcedimentosEstabelecimento.save({ id, ie_status: ! procedimento.ie_status }).then(response => {
                    this.waitingStatus = 0
                    if ([200, 201, 202, 203, 204].includes(response.status)) {
                        procedimento['ie_status'] = response.data.ie_status
                        if (procedimento.ie_status)
                            this.$toast.success('Procedimento habilitado', { duration: 3000 })
                        else
                            this.$toast.success('Procedimento desabilitado', { duration: 3000 })
                    } else if (response.status === 400) {
                        this.$toast.error(response.data.detail, { duration: 3000 })
                    }
                })
            },
        }
    }
</script>
